
import { Component, Vue } from "vue-property-decorator";
import { getCurrentBrowser } from "@/utils/sniffing";
import { browserSniffingMessage } from "@/utils/literals";
import { assetsUrl } from "@/env";

@Component({ name: "NotSupported" })
export default class NotSupported extends Vue {
  currentBrowser: string | undefined;
  message: string | undefined;

  created(): void {
    this.currentBrowser = getCurrentBrowser(navigator.userAgent);
    this.message = browserSniffingMessage(this.currentBrowser);
  }

  getImageSrc(slug: string): string {
    return `${assetsUrl}/svg/${slug}`;
  }
}
